.timeline {
    border-left: 1px solid #79201b;
    position: relative;
    list-style: none;
  }
  
  .timeline .timeline-item {
    position: relative;
  }
  
  .timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
  }
  
  .timeline .timeline-item:after {
    left: -38px;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
  }

  .timeline-item-past:after {
    background-color: #a82e27;
  }
  .timeline-item-active:after {
    background-color: #d87a2c;
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
  .timeline-item-upcoming:after {
    background-color: #b4b1af;
  }

  .timeline-item-active:before {
    width: 11px;
    height: 11px;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(.8);
    }
  }